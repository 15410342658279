import { GetLatestPostsDocumentParams } from '@/constants/defaultParams';
import { useGetPostsQuery } from '@/generated/graphql';
import { IPostCardProps } from '@/interfaces/card';
import format from '@/utils/format';
import Link from 'next/link';
import Slider from 'react-slick';

const LatestNews = () => {
  const { data } = useGetPostsQuery({
    variables: GetLatestPostsDocumentParams,
  });

  const latestNews = format.formatDataPost(data?.posts);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: 'linear',
  };

  return (
    <div className="flex gap-2 items-center mb-3 w-full overflow-hidden">
      <p className="text-base whitespace-nowrap font-bold text-LightGray bg-primary rounded-md py-0.5 px-2 shadow-[0_4px_4px_0_rgba(0,0,0,0.25)]">
        TIN MỚI NHẤT
      </p>

      <div className="w-full">
        <Slider {...settings}>
          {latestNews?.map((item: IPostCardProps, index: any) => (
            <Link
              key={index}
              href={`/${item.category?.slug}/${item.slug}-${item.id}`}
              className={`block h-full w-fit relative`}
            >
              <p className="text-xs font-medium text-DarkBlue hover:text-primary">
                {item.title}
              </p>
            </Link>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default LatestNews;
